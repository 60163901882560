<template>
  <div class="leftNav">
    <ul>
      <li class="nav-item item1" :class="{'actived' : activeState === 0}"  @click="navClick(0)"><img src="@/assets/images/icon/icon-8.png">我的课程</li>
      <li class="nav-item item2" :class="{'actived' : activeState === 1}"  @click="navClick(1)"><img src="@/assets/images/icon/icon-9.png">我的订单</li>
      <li class="nav-item item3" :class="{'actived' : activeState === 2}"  @click="navClick(2)"><img src="@/assets/images/icon/icon-10.png">课程协议</li>
      <li class="nav-item item4" :class="{'actived' : activeState === 3}"  @click="navClick(3)"><img src="@/assets/images/icon/icon-11.png">疑问与帮助</li>
      <li class="nav-item item5" :class="{'actived' : activeState === 4}"  @click="navClick(4)"><img src="@/assets/images/icon/icon-12.png">基本信息</li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "leftNav",
  methods: {
    navClick(val = 0) {
        this.$router.push({path:`/mine?type=${val}`} )
        // this.$emit('onChange',val)
    }
  },
  computed: {
    activeState() {
      // 路由取值
      if(this.$route.name === 'OrderDetail') {
        return 1
      }else if(this.$route.name === 'ProtocolDetail') {
        return 2
      } else {
        return +this.$route.query.type || 0
      }
    }
  }
}
</script>
<style lang="less">
.leftNav{
  width:215px;
  height: 100%;
  background:#fff;
  float:left;
  ul{
    margin:0;
    padding:0;
    // padding:0 10px;
    li{
      width: 215px;
      height:75px;
      line-height: 75px;
      border-bottom:1px solid #E8E8E8;
      cursor: pointer;
      img{
        float:left;
        margin:26px 14px 26px 34px;
      }
    }
    .item1 img{
      margin-top:30px;
    }
    .item3 img{
      margin-top:28px;
    }
  }
  .actived{
    background: #F2F2F2;
  }
}
</style>
<template>
  <div class="banner-box">
    <div class="mineBanner">
      <div v-if="getUserInfo">
          <div class="headimg" v-if="getUserInfo.headImg">
            <img :src="getUserInfo.headImg">
          </div>
          <div class="headimg" v-else>
            <img src="@/assets/images/mine/headImg.png">
          </div>
      </div>
      <div class="banner_right"  v-if="getUserInfo">
          <!-- <p>{{getUserInfo.phone | phoneFilter}}，下午好</p> -->
          <p>{{getUserInfo.name}}，下午好</p>
          <span>选博学 赢未来</span>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
    name: "banner",
    computed: {
      getUserInfo() {
        if(this.$store.state) {
          return this.$store.state.userInfo || null
        } else {
          return null
        }
      }
      
    },
    methods: {

    },
    filters: {
      phoneFilter(val) {
        if(val) {
          return `${val.substr(0,3)}****${val.substr(7)}`
        } else {
          return ''
        }
      }
    }
}
</script>

<style lang="less">
.banner-box{
  width:100%;
  height:150px;
  margin:0px auto 35px auto;
  background:url(../../assets/images/mine/banner.png);
}
.mineBanner{
    width:1200px;
    height:150px;
    margin:0 auto;
    .headimg{
      width:80px;
      height:80px;
      float:left;
      margin:35px 25px 35px 32px;
      img{
        width: 100%;
        height: 100%;
        background:#eee;
        border: none;
        border-radius: 50%;
    }
    }
    
    .banner_right{
        height:80px;
        line-height: 40px;
        margin:35px 0;
        float:left;
        color:#fff;
        p{
            font-size: 22px;
        }
        span{
            font-size: 18px;
        }
        
    }
}
</style>